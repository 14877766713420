export const BaseUrl =
  process.env.VUE_APP_BASE_URL || "https://www.be.zerogames.org";

export const USER = "/user";
export const ACCOUNT = "/account";
export const DISTRIBUTOR = "/distributor";
export const PLATFORM = "/platform";

export const GET_USER = `${USER}/get_user`;
export const SECONDARY_LOGIN = `${USER}/secondary_login`;
export const EMAIL_VERIFICATION = `${USER}/email_verification`;
export const SET_PRIMARY = `${USER}/set_primary`;
export const DELETE_USER = `${USER}/delete_sub_user`;
export const STATUS = `${USER}/update_status`;
export const EMAIL = `${USER}/emails`;
export const PLATFORMS = `${USER}/platforms`;
export const CREATE_USER = `${USER}/signup`;
export const LOGIN_USER = `${USER}/login`;

export const CREATE_PLATFORM = `${PLATFORM}/create`;
export const DELETE_PLATFORM = `${PLATFORM}/`;



// accounts end points
export const GET_ACCOUNTS = `${ACCOUNT}/get_accounts`;
export const GET_AGENTS_STATS = `${ACCOUNT}/get_agents_stats`;
export const ADMIN_SECRET = `${ACCOUNT}/admin_secret`;
export const DELETE_ACCOUNT = `${DISTRIBUTOR}/delete_account`;


// distributor end points

export const SIGNUP_DISTRIBUTOR = `${DISTRIBUTOR}/signup`;
export const LOGIN_DISTRIBUTOR = `${DISTRIBUTOR}/login`;
export const GET_DISTRIBUTORS = `${DISTRIBUTOR}/get_distributors`;
export const GET_DISTRIBUTOR_ACCOUNT_STATS = `${DISTRIBUTOR}/get_distributor_account_stats`;
export const DISTRIBUTOR_CREATE_ACCOUNT = `${DISTRIBUTOR}/create_account`;
